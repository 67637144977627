import React from "react"
import Container from "../components/Container"
import H1 from "../components/H1"
import H2 from "../components/H2"
import HeroHeader from "../components/HeroHeader"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"

const FishingPage = ({ data }) => (
  <Layout>
    <SEO title="Fishing tools by Steven Musumeche" keywords={[`fishing tools`]} />
    <HeroHeader textAlign="left">
      <Container>
        <H1>Fishing</H1>
        <H2>
          I'm a saltwater fishing fanatic, and I created these tools for use by
          myself, and other anglers.
        </H2>
      </Container>
    </HeroHeader>
    <Container>
      <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
    </Container>
  </Layout>
)

export default FishingPage

export const query = graphql`
  {
    markdownRemark(frontmatter: { id: { eq: "fishing" } }) {
      html
    }
  }
`
